export const POST_DOG = 'POST_DOG';
export const POST_USER = 'POST_USER';
export const GET_USERS = 'GET_USERS';
export const GET_CLOUDINARY_RESPONSE = 'GET_CLOUDINARY_RESPONSE';
export const CLEAR_CLOUDINARY_RESPONSE = 'CLEAR_CLOUDINARY_RESPONSE';
export const GET_DOGS = 'GET_DOGS';
export const GET_DOGS_DETAILS = 'GET_DOGS_DETAILS';
export const CLEAR_DETAILS = 'CLEAR_DETAILS';
export const CLEAR_ALL_DOGS = 'CLEAR_ALL_DOGS';
export const FILTER_DOGS_BY_GENDER = 'FILTER_DOGS_BY_GENDER';
export const FILTER_DOGS_BY_AGE = 'FILTER_DOGS_BY_AGE';
export const FILTER_DOGS_BY_SIZE = 'FILTER_DOGS_BY_SIZE';
export const GET_PRESS = 'GET_PRESS';
export const POST_ADOPTION = 'POST_ADOPTION';
export const POST_CONTRIBUTION = 'POST_CONTRIBUTION';
export const POST_VOLUNTEER = 'POST_VOLUNTEER';
export const POST_MELI = 'POST_MELI';
export const GET_USERS_EMAIL = 'GET_USERS_EMAIL';
export const POST_CONTACTO = 'POST_CONTACTO';
export const GET_PROYECTOS = 'GET_PROYECTOS';
export const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA';
export const DELETE_CAPTCHA_VALIDATE = 'DELETE_CAPTCHA_VALIDATE';
